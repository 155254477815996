
import { createRouter, createWebHashHistory } from 'vue-router'
 
const routes = [
  { path: '/', redirect: { name: 'FSTime' } },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/home.vue')
  },
  {
    path: '/tqx',
    name: 'tqx',
    component: () => import('@/pages/tongquxiang/index.vue')
  },
  {
    path: '/FSTime',
    name: 'FSTime',
    component: () => import('@/pages/fsTime/index.vue')
  },
  {
    path: '/watch',
    name: 'watch',
    component: () => import('@/pages/watch/index.vue')
  },
  {
    path: '/gushi',
    name: 'gushi',
    component: () => import('@/pages/watch/gushi.vue')
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import('@/pages/watch/resources.vue')
  },
  {
    path: '/voice',
    name: 'voice',
    component: () => import('@/pages/watch/voice.vue')
  },
  {
    path: '/img',
    name: 'img',
    component: () => import('@/pages/watch/img.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/pages/watch/video.vue')
  },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
 
export default router
