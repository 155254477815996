import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
// 引入echarts
import echarts from "./utils/echarts";
import axios from 'axios'

import { Uploader, Dialog, showConfirmDialog,
Field,
} from 'vant';
import 'vant/lib/index.css';


const app = createApp(App)


app.config.globalProperties.$echarts = echarts; 
app.config.globalProperties.$axios = axios; 
app.provide('$echarts', echarts); // vue3采用provide, inject方式使用
app.provide('$axios',axios)







app.use(router)
app.use( Uploader );
app.use( Field );
app.use(Dialog, showConfirmDialog );
app.mount('#app')